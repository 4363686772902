@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100dvh;
  scroll-behavior: smooth;
}
